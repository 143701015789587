<template>
	<div class="bg-indigo-1000 w-screen h-screen">
		<div class="flex relative items-center h-screen w-screen justify-center">
			<form action="" method="POST" @submit.prevent="login">
				<img src="@/assets/iconos/icono.png" class="w-64 -mb-3" alt="" />
				<div class="py-3 flex justify-center">
					<input
						class="rounded shadow-lg text-base w-56"
						type="email"
						v-model="username"
						name="username"
						id="username"
						placeholder="Email..."
					/>
				</div>

				<div class="py-3 flex justify-center">
					<input
						class="rounded shadow-lg text-base w-56"
						type="password"
						v-model="password"
						name="password"
						id="password"
						placeholder="Password..."
					/>
				</div>
				<div class="py-3 flex justify-center">
					<button
						class="bg-blue-500 w-full shadow-lg h-8 rounded-md hover:bg-blue-300 text-xl text-white"
						type="submit"
					>
						Login
					</button>
				</div>

				<!-- <div class="py-3 flex justify-center">
					<a class="text-white text-xl" href="/password_recovery"
						>¿Olvido su contraseña?</a
					>
				</div> -->

				<div class="mt-10 flex justify-center">
					<a
						class="text-white text-xl"
						target="_blank"
						href="https://codingtel.com/"
						>Powered by Codingtel C.A.</a
					>
				</div>
			</form>
		</div>
	</div>
</template>

<script>
import gql from "graphql-tag";
import { onLogin } from "../vue-apollo.js";

export default {
	data: () => {
		return {
			username: "",
			password: ""
		};
	},
	methods: {
		login() {
			// Call to the graphql mutation
			this.$store.state.isLoading = true;
			this.$apollo
				.mutate({
					// Query
					mutation: gql`
						mutation($data: LoginInput!) {
							login(input: $data) {
								access_token
								user {
									roles {
										name
									}
								}
							}
						}
					`,
					// Parameters
					variables: {
						data: {
							username: this.username,
							password: this.password
						}
					}
				})
				.then(data => {
					// Result
					//console.log(data.data.login.user.roles[0].name);
					onLogin(
						this.$apollo.provider.defaultClient,
						data.data.login.access_token
					);
					this.$store.state.isLoading = false;
					const vau = {};
					vau.token = data.data.login.access_token;
					vau.role = data.data.login.user.roles[0].name;
					this.$store.state.role = vau.role;
					window.localStorage.setItem("vau", JSON.stringify(vau));
					this.$store.commit("whenLoginIn");
					if (data.data.login.user.roles[0].name === "superAdmin") {
						this.$router.push("/");
						setTimeout(() => {
							this.$router.go(0);
						}, 500);
					}
					if (data.data.login.user.roles[0].name === "gerente") {
						this.$router.push("/");
						setTimeout(() => {
							this.$router.go(0);
						}, 500);
					}
					if (data.data.login.user.roles[0].name === "vendedor") {
						this.$router.push("/VHome");
						setTimeout(() => {
							this.$router.go(0);
						}, 500);
					}
					if (data.data.login.user.roles[0].name === "cliente")
						this.$router.push("/404");
					if (data.data.login.user.roles[0].name === "proveedor")
						this.$router.push("/404");
				})
				.catch(error => {
					// Error
					this.$store.state.isLoading = false;
					alert(
						"Credenciales invalidas, por favor vuelva a intentar. Si el error persiste verifique su conexión de internet."
					);
					console.error(error);
				});
		}
	}
};
</script>
